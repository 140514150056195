<template>
    <b-row class="match-height">
        <b-col cols="12">
            <b-card :title="this.$route.meta.title">
                <b-alert v-model="showDismissibleAlert" v-id="showDismissibleAlert == true" v-height-fade dismissible
                    fade class="mb-2" variant="danger">
                    <div class="alert-body">
                        <span>{{ messages }}</span>
                    </div>
                </b-alert>
                <ValidationObserver v-slot="{ handleSubmit }">
                    <b-form @submit.prevent="handleSubmit(save)">
                        <b-row>
                            <b-col md="12">
                                <ValidationProvider v-slot="{ errors }" name="Pilih Regional" rules="required">
                                    <b-form-group>
                                        <label label-for="regional">
                                            Pilih Regional <span class="fs-6 text-danger">*</span>
                                        </label>
                                        <v-select id="regional" v-model="model.code_regional" placeholder="Pilih Regional..." :options="regionalLists"
                                            :reduce="item => item.idwilayah" label="nmwilayah" @input="updateNameRegional" />

                                        <p class="fs-6 text-danger">
                                            {{ errors[0] }}
                                        </p>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>

                            <b-col md="6">
                                <ValidationProvider v-slot="{ errors }" name="Periode Awal" rules="">
                                    <b-form-group>
                                        <label label-for="start_date">Periode Awal </label>
                                        <date-picker v-model="model.start_date" :firstDayOfWeek="1"
                                            placeholder="Masukkan Periode Awal" name="start_date" :lang="lang"
                                            :format="formats" value-type="YYYY-MM-DD"></date-picker>
                                        <p class="fs-6 text-danger">
                                            {{ errors[0] }}
                                        </p>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>

                            <b-col md="6">
                                <ValidationProvider v-slot="{ errors }" name="Periode Akhir" rules="">
                                    <b-form-group>
                                        <label label-for="end_date">Periode Akhir </label>
                                        <date-picker v-model="model.end_date" :firstDayOfWeek="1"
                                            placeholder="Masukkan Periode Akhir" name="end_date" :lang="lang"
                                            :format="formats" value-type="YYYY-MM-DD"></date-picker>
                                        <p class="fs-6 text-danger">
                                            {{ errors[0] }}
                                        </p>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>

                            <b-col md="12">
                                <ValidationProvider v-slot="{ errors }" name="Total Anggaran" rules="">
                                    <b-form-group>
                                        <label>Total Anggaran <span class="fs-6 text-danger">*</span></label>
                                        <vue-numeric
                                        v-model="model.anggaran"
                                        name="total_anggaran"
                                        @keypress.native="NumbersOnly"
                                        class="form-control"
                                        placeholder="Masukkan Total Anggaran"
                                        currency="Rp"
                                        separator="."
                                        ></vue-numeric>
                                        <p class="fs-6 text-danger">
                                        {{ errors[0] }}
                                        </p>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                        <b-card-footer>
                            <!-- submit and reset -->
                            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary"
                                class="mr-1 float-right">
                                Simpan
                            </b-button>
                            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" class="mr-1 float-right"
                                variant="outline-secondary" :to="{ name: 'regional' }">
                                Batal
                            </b-button>
                        </b-card-footer>
                    </b-form>
                </ValidationObserver>
            </b-card>
        </b-col>
    </b-row>
</template>

<style>
.mx-datepicker {
    width: 100% !important;
}

.vs__dropdown-toggle {
    min-width: 100% !important;
    max-width: 100% !important;
}

.v-select.vs--single .vs__selected {
    min-width: 100% !important;
    max-width: 100% !important;
}
</style>

<script>
import {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
    BCardFooter,
    BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { heightFade } from '@core/directives/animations'
import { mask } from 'vue-the-mask'

const userData = JSON.parse(localStorage.getItem('userData'))

export default {
    components: {
        BCard,
        BCardFooter,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BForm,
        BButton,
        vSelect,
        BAlert,
    },
    directives: {
        Ripple,
        'height-fade': heightFade,
        mask,
    },
    data() {
        return {
            name: 'UsersForm',
            errors: {
            },
            showDismissibleAlert: false,
            config: {
                api: '/budgets',
                regional: '/misc/regionals',
                companies: '/misc/companies',
                redirect: 'regional',
            },
            model: {
                code_regional: '',
                name_regional: '',
                periode_awal: '',
                periode_akhir: '',
                anggaran: '',
                type: 'regional'
            },
            companies: [],
            regionalLists: [],
            messages: '',
            formats: 'DD/MM/YYYY',
            lang: {
                days: ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
                months: [
                    'Jan',
                    'Feb',
                    'Mar',
                    'Apr',
                    'Mei',
                    'Jun',
                    'Jul',
                    'Agu',
                    'Sep',
                    'Oct',
                    'Nov',
                    'Dec',
                ],
                pickers: [
                    'next 7 days',
                    'next 30 days',
                    'previous 7 days',
                    'previous 30 days',
                ],
                placeholder: {
                    date: 'Select Date',
                    dateRange: 'Select Date Range',
                },
            },
            firstTimeGetCompanies: false,
        }
    },
    watch: {
        'model.regional': function (current, oldValue) {
            // if (current !== oldValue && current && !this.firstTimeGetCompanies) {
            //     this.getCompaniesByRegional()
            // }
        },
    },
    mounted() {
        this.getRegionalList()
        if (this.model.regional && this.$route.meta.action === 'store') {
            this.firstTimeGetCompanies = false
            // this.getCompaniesByRegional()
        }
        if (this.$route.meta.action != 'store') {
            this.getData()
        }
    },
    methods: {
        updateNameRegional(selectedKode) {
            const selectedRegional = this.regionalLists.find(
            (item) => item.idwilayah === selectedKode
            );
            this.model.name_regional = selectedRegional
            ? selectedRegional.nmwilayah
            : null;
        },
        getRegionalList() {
            axios.get(this.config.regional).then(response => {
                this.regionalLists = response.data.data
            })
        },
        numbersOnly(event) {
            const evt = event || window.event
            const charCode = evt.which ? evt.which : evt.keyCode
            if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                evt.preventDefault()
            } else {
                return true
            }
        },
        getData() {
            if (this.$route.meta.action == 'edit') {
                this.firstTimeGetCompanies = false
                axios
                    .get(`${this.config.api}/${this.$route.params.id}`)
                    .then(response => {
                        const { data } = response.data
                        this.model = {
                            ...data,
                            regional: data.regional || userData?.regional,
                            level: +data.level,
                        }
                        // this.getCompaniesByRegional()
                    })
            }
        },
        save() {
            let _ = this
            
            const { code_regional,name_regional, start_date, end_date, anggaran, type } = _.model
            const data = {
                code_regional,
                name_regional,
                start_date,
                end_date,
                anggaran,
                type
            }
            console.debug(data)
            if (this.$route.meta.action == 'store') {
                axios
                .post(_.config.api + '/' + type, data)
                .then(res => {
                    _.$router.push({
                    name: _.config.redirect,
                    params: {
                        event: 'success',
                        title: 'Tambah Data Berhasil',
                        text: 'Data baru berhasil ditambahkan',
                    },
                    })
                })
                .catch(e => {
                    let vm = this
                    vm.showDismissibleAlert = true
                    if (typeof e.response.data.message === 'object') {
                    vm.errors = e.response.data.data
                    } else {
                    vm.messages = e.response.data.message
                    vm.errors = e.response.data.data
                    }
                })
            } else {                
                axios
                .put(_.config.api + '/' + _.$route.params.id, data)
                .then(res => {
                    _.$router.push({
                    name: _.config.redirect,
                    params: {
                        event: 'success',
                        title: 'Ubah Data Berhasil',
                        text: 'Data berhasil diubah',
                    },
                    })
                })
                .catch(e => {
                    let vm = this
                    vm.showDismissibleAlert = true
                    if (typeof e.response.data.message === 'object') {
                    vm.errors = e.response.data.data
                    } else {
                    vm.messages = e.response.data.message
                    vm.errors = e.response.data.data
                    }
                })
            }
            },
    },
}
</script>